import React from "react"
import styled from "styled-components"
import PageTemplate from "../templates/pages/pageTemplate"

export default function Agb(props) {
  return (
    <PageTemplate title="Allgemeine Geschäftsbedingungen">
      <Styled>
        <div className="section">
          <h3>Versand</h3>
          <p>Siehe Artikeldetails für voraussichtliche Liefertermine.</p>
          <p>
            Aktuell werden Bestellungen nur innerhalb von Deutschland versandt.
          </p>
          <h6>Zoll- und Einfuhrgebühren</h6>
          <p>
            Käufer sind für eventuell anfallende Zoll- oder Einfuhrgebühren
            verantwortlich. Ich bin nicht für Verzögerungen verantwortlich, die
            durch den Zoll entstehen.
          </p>
        </div>
        <div className="section">
          <h3>Zahlungsmöglichkeiten</h3>
          <p>Die Zahlung wird über PayPal abgewickelt.</p>
          <p>
            Folgende Zahlungsarten stehen zur Verfügung:
            <ul>
              <li>Paypal</li>
              <li>Lastschrift</li>
              <li>Kreditkarte</li>
              <li>giropay</li>
              <li>Sofort</li>
            </ul>
          </p>
        </div>
        <div className="section ">
          <h3>Rückgaben &amp; Umtausch</h3>
          <h6>Ich akzeptiere Rückgaben und Umtausch</h6>
          <p>Kontaktiere mich innerhalb von: 14 Tagen nach der Lieferung</p>
          <p>Sende Artikel zurück innerhalb von: 30 Tagen nach der Lieferung</p>
          <p>
            Für personalisierte Produkte ist kein Widerruf möglich. Anderes
            gilt, wenn die Produkte bei der Lieferung defekt oder beschädigt
            waren.
          </p>

          <h6 className="mt-4">Ich akzeptiere keine Stornierungen</h6>
          <p>
            Aber bitte kontaktiere mich, falls du irgendein Problem mit deiner
            Bestellung hast.
          </p>

          <h6>Rückgabebedingungen</h6>
          <p>
            Käufer tragen die Versandkosten für Rückgaben. Falls der Artikel
            nicht in seinem Originalzustand zurückgegeben wird, ist der Käufer
            für jeglichen Wertverlust verantwortlich.
          </p>
        </div>
        <div className="small">Zuletzt aktualisiert: 03. September 2022</div>
      </Styled>
    </PageTemplate>
  )
}

const Styled = styled.div`
  .section {
    padding-bottom: 1.5rem !important;
    margin-bottom: 3rem !important;
    border-bottom: 1px solid #dee2e6 !important;
    h3 {
      margin-bottom: 2rem !important;
    }
    h6 {
      font-weight: 700;
      margin-top: 2rem !important;
      margin-bottom: 1rem !important;
    }
    p {
      margin-bottom: 1rem !important;
    }
  }
`
